<template>
  <vx-card
    class="add-activity-card"
    style="margin-top: 20px; border-radius: 8px;"
  >
    <form>
      <h2>{{ selectedActivity ? "Edit" : "Add" }} Activity</h2>
      <h3 class="h4">Core Skill</h3>
      <ul class="centerx flex-wrap">
        <li
          v-for="(index, key) in coreSkills"
          style="display: inline-flex; flex-wrap: wrap;"
          :key="key"
        >
          <input
            type="radio"
            name="coreSkillId"
            v-model="coreSkillId"
            :value="index._id"
            v-validate="'required'"
          />
          <div class="radio-wrap">
            <div>
              <img
                :src="index.icon ? index.icon : $defaultImage"
                :alt="index.name"
                height="50px"
                width="50px"
              />
              <span>{{ index.name }}</span>
            </div>
          </div>
        </li>
      </ul>
      <span class="text-danger text-sm" v-show="errors.has('coreSkillId')">{{
        errors.first("coreSkillId")
      }}</span>
      <br />
      <h3 class="h4">Ways Of Learning</h3>

      <ul class="centerx flex-wrap">
        <li
          v-for="(index, key) in waysOfLearningList"
          style="display: inline-flex; flex-wrap: wrap;"
          :key="key"
        >
          <input
            type="radio"
            name="waysOfLearningId"
            v-model="waysOfLearningId"
            :value="index._id"
            v-validate="'required'"
          />
          <div class="radio-wrap">
            <div>
              <img
                :src="index.icon ? index.icon : $defaultImage"
                :alt="index.name"
                height="50px"
                width="50px"
              />
              <span>{{ index.name }}</span>
            </div>
          </div>
        </li>
      </ul>
      <span
        class="text-danger text-sm"
        v-show="errors.has('waysOfLearningId')"
        >{{ errors.first("waysOfLearningId") }}</span
      >
      <br />
      <h3 class="h4">STREAM</h3>

      <ul class="centerx flex-wrap">
        <li
          style="display: inline-flex; flex-wrap: wrap;"
          v-for="(index, key) in streams"
          :key="key"
        >
          <input
            type="radio"
            name="streamId"
            v-model="streamId"
            :value="index._id"
            v-validate="'required'"
          />
          <div class="radio-wrap">
            <div>
              <img
                :src="index.icon ? index.icon : $defaultImage"
                :alt="index.name"
                height="50px"
                width="50px"
              />
              <span>{{ index.name }}</span>
            </div>
          </div>
        </li>
      </ul>
      <span class="text-danger text-sm" v-show="errors.has('streamId')">{{
        errors.first("streamId")
      }}</span>
      <br />
      <vs-row>
        <vs-col class="vs-col" vs-lg="10" vs-sm="12">
          <div class="mb-6">
            <div class="w-full mb-3">
              <vue-simple-suggest
                pattern="\w+"
                v-model="name"
                name="name"
                :list="getList"
                :max-suggestions="10"
                :min-length="1"
                :debounce="200"
                :filter-by-query="true"
                :prevent-submit="true"
                :controls="{
                  selectionUp: [38, 33],
                  selectionDown: [40, 34],
                  select: [13, 36],
                  hideList: [27, 35]
                }"
                :mode="'input'"
                :nullable-select="true"
                ref="suggestComponent"
                value-attribute="id"
                display-attribute="title"
                v-validate="'required'"
                @select="onSuggestSelect"
              >
                <div
                  :class="
                    name !== '' ? 'g input-field hasValue' : 'g input-field'
                  "
                >
                  <input
                    type="text"
                    v-validate="'required'"
                    class="vs-input--input"
                    :value="name"
                  />
                  <span class="vs-placeholder-label">Activity</span>
                </div>

                <div
                  slot="suggestion-item"
                  slot-scope="scope"
                  :title="scope.suggestion.description"
                >
                  <div class="text">
                    <span
                      v-html="
                        scope.suggestion.title +
                          ' - ' +
                          scope.suggestion.description
                      "
                    ></span>
                  </div>
                </div>

                <div
                  class="misc-item"
                  slot="misc-item-below"
                  slot-scope="{ suggestions }"
                  v-if="loading"
                >
                  <span>Loading...</span>
                </div>
              </vue-simple-suggest>

              <span class="text-danger text-sm" v-show="errors.has('name')">{{
                errors.first("name")
              }}</span>
            </div>
          </div>
          <div class="mb-2">
            <div class="w-full">
              <vs-textarea
                :class="description ? 'hasValue' : ''"
                :label="'Description'"
                v-model="description"
                name="description"
                v-validate="'required'"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('description')"
                >{{ errors.first("description") }}</span
              >
            </div>
          </div>
          <vs-button
            type="filled"
            @click.prevent="submitActivity"
            class="mt-5 block ml-auto btn-blue-border"
            >Add Activity
          </vs-button>
        </vs-col>
      </vs-row>
    </form>
  </vx-card>
</template>

<script>
import { mapActions } from "vuex";
import VueSimpleSuggest from "vue-simple-suggest";
import "vue-simple-suggest/dist/styles.css"; // Optional CSS
import { Validator } from "vee-validate";

const dictionary = {
  en: {
    attributes: {
      coreSkillId: "core skill",
      waysOfLearningId: "ways of learning",
      streamId: "stream"
    }
  }
};

Validator.localize(dictionary);
export default {
  components: {
    VueSimpleSuggest
  },
  props: ["startOfWeek", "endOfWeek", "selectedActivity"],
  data() {
    return {
      coreSkillId: "",
      name: "",
      description: "",
      waysOfLearningId: "",
      streamId: "",
      selected: "",
      loading: false,
      startTime: new Date(),
      startDate: "",
      endDate: "",
      isSpontaneous:false
    };
  },
  methods: {
    ...mapActions("activity", [
      "storeActivity",
      "fetchActivityByLikeName",
      "updateActivityDetail"
    ]),
    initValues() {
      this.name = "";
      this.description = "";
      this.coreSkillId = null;
      this.waysOfLearningId = null;
      this.streamId = null;
      // this.isSpontaneous = false;
    },
    submitActivity() {
      this.$vs.loading();
      this.$validator.validateAll().then(async result => {
        if (result) {
          const endTime = new Date();
          const timeTaken =
            (endTime.getTime() - this.startTime.getTime()) / 1000;

          let obj = new FormData();
          obj.append("name", this.name);
          obj.append("description", this.description);
          obj.append("coreSkills", this.coreSkillId);
          obj.append("waysOfLearnings", this.waysOfLearningId);
          obj.append("stream", this.streamId);
          obj.append("startedAt", this.startDate);
          obj.append("endedAt", this.endDate);
          obj.append("isSpontaneous", this.isSpontaneous);
          if (!this.selectedActivity) {
            obj.append("learningCenterId", this.teacher.learningCenterId);
            obj.append("roomId", this.teacher.roomId);
            obj.append("teacherId", this.teacher._id);
            obj.append("timeTaken", timeTaken);
          }

          let data = {
            obj: obj,
            config: {
              header: {
                "Content-Type": "image/png"
              }
            }
          };
          if (!this.selectedActivity) {
            await this.storeActivity(data)
              .then(c => {
                this.$vs.loading.close();
                this.showMessage(
                  "Success",
                  "Activity created successfully.",
                  "success",
                  "icon-check-circle"
                );
                
                setTimeout(()=>location.reload(), 1000)
              })
              .catch(err => {
                this.$vs.loading.close();
                if (err.response.status === 403) {
                  alert(
                    "You dont have permission to visit this page. Please upgrade your package."
                  );
                  this.$router.push("/teacher/dashboard");
                }
                this.showMessage("Error", err.message, "error", "icon-times");
              });
          } else {
            data.activityId = this.selectedActivity._id;
            await this.updateActivityDetail(data)
              .then(c => {
                this.$vs.loading.close();
                this.showMessage(
                  "Success",
                  "Activity updated successfully.",
                  "success",
                  "icon-check-circle"
                );
              })
              .catch(err => {
                this.$vs.loading.close();
                this.showMessage("Error", err.message, "error", "icon-times");
              });
          }
          this.initValues();
          this.$emit("refreshActivities");
          this.$parent.activityFormDisplay = false;
          this.$parent.editPageDisplay = true;
          this.$emit("getActivity");
        }
        this.$vs.loading.close();
      });
    },
    showMessage(title, message, color, icon) {
      this.$vs.notify({
        title: title,
        text: message,
        color: color,
        iconPack: "feather",
        position: "top-right",
        icon: icon
      });
    },
    getList(inputValue) {
      return new Promise(async (resolve, reject) => {

        let data= {
          learningCenterId:this.teacher.learningCenterId,
          inputValue:inputValue}

        await this.fetchActivityByLikeName(data)
          .then(response => {
            const jsondata = JSON.stringify(response.data.data);
            const parsed = JSON.parse(jsondata);
            const result = [];
            parsed.forEach(searchResult => {
              result.push({
                id: searchResult._id,
                title: searchResult.name,
                description: searchResult.description,
                link: ""
              });
            });
            resolve(result);
          })
          .catch(error => {
            this.loading = false;
            reject(error);
          });
      });
    },
    onSuggestSelect(suggest) {
      this.selected = suggest.title;
      this.description = suggest.description;
    },
    boldenSuggestion(scope) {
      if (!scope) return scope;
      const { suggestion, query } = scope;
      let result = this.$refs.suggestComponent.displayProperty(
        suggestion.title
      );
      if (!query) return result;
      const texts = query.split(/[\s-_/\\|.]/gm).filter(t => !!t) || [""];
      return result.replace(
        new RegExp("(.*?)(" + texts.join("|") + ")(.*?)", "gi"),
        "$1<b>$2</b>$3"
      );
    }
  },
  mounted() {
    if (this.selectedActivity) {
      this.coreSkillId = this.selectedActivity.coreSkills[0]._id;
      this.waysOfLearningId = this.selectedActivity.waysOfLearnings[0]._id;
      this.streamId = this.selectedActivity.streams[0]._id;
      this.name = this.selectedActivity.name;
      this.description = this.selectedActivity.description;
    }
    this.startDate = this.startOfWeek;
    this.endDate = this.endOfWeek;
  },
  beforeMount() {
    this.errors.clear();
    this.$nextTick(() => {
      this.$validator.reset();
    });
  },
  computed: {
    coreSkills() {
      return this.$store.state.coreSkill.coreSkills;
    },
    streams() {
      return this.$store.state.stream.streams;
    },
    waysOfLearningList() {
      return this.$store.state.waysOfLearning.waysOfLearning;
    },
    teacher() {
      return this.$store.state.AppActiveUser;
    }
  }
};
</script>
